import { API } from '../../../Services/axios';
import { Button, Card, Col, Form, Input, Row, Typography, notification } from 'antd';
import { getConfirmPasswordRule, getPasswordRule } from '../../../Utils/Rules/registration';
import { login } from '../../../Utils/login';
import { useErrorModal } from '../../../Components/Modals/ErrorModal';
import { useForm } from 'antd/lib/form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

const SetPasswordPage = () => {
  const { showErrorModal } = useErrorModal();
  const { registrationLink } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      registrationLink: registrationLink,
      password: values.password,
    };

    API.put('/api/v1/register/operator/set-operator-password', data)
      .then((response) => {
        notification.success({ message: 'Пароль успешно установлен', placement: 'top' });
        setTimeout(() => {
          login(navigate);
        }, 2000);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <Row justify="center" className="operator__header">
      <Col flex="650px" className="operator__action-col">
        <Card title={<Typography.Title level={4}>Установите пароль для своей учетной записи</Typography.Title>}>
          <Form form={form} layout="vertical" colon={false} className="form" onFinish={onFinish}>
            <Form.Item name="password" label="Пароль" validateFirst rules={getPasswordRule()}>
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Повторите пароль"
              validateFirst
              rules={getConfirmPasswordRule()}
              dependencies={['password']}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" size="large" className="registration__button" loading={loading}>
                Установить пароль
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default SetPasswordPage;
