import { Roles } from '../../Constants/Profile';
import { useAppSelector } from '../../Store/hooks';
import Loader from '../Loader/Loader';
import NoAccessPage from '../ResultPage/NoAccessPage';

interface ProtectedRouteUnterface {
  loading: boolean;
  children: JSX.Element;
  routeRole: Roles;
}

const ProtectedRoute = (props: ProtectedRouteUnterface) => {
  const { loading, children, routeRole } = props;
  const role = useAppSelector((state) => state.user.role);

  if (loading || !role) {
    return <Loader />;
  } else if (routeRole !== role) {
    return <NoAccessPage />;
  }
  return children;
};

export default ProtectedRoute;
