import { API } from '../Services/axios';
import { Profile } from '../Models/Profile';
import { Roles } from '../Constants/Profile';

// Saves user basic data to localStorage
export const setLocalStorage = (data: Profile) => {
  const savingData = JSON.stringify({
    role: data.type,
    name: data.name,
    surname: data.surname,
    avatarLink: data.avatar?.link,
    phone: data.phone,
    confirmed: data.organisation?.confirmed,
    calculatorFeedbackEnabled: data.calculatorFeedbackEnabled,
  });
  localStorage.setItem('userInfo', savingData);
  if (!localStorage.getItem('sizePage')) {
    localStorage.setItem('sizePage', '20');
  }
};

// Login function
// Sends request for profile, saves received data to localStorage
// and navigate user to their main page depending on their role
export const login = async (navigate) => {
  const profileData = await API.get('/api/v1/profile/');
  if (profileData.status === 200) {
    setLocalStorage(profileData.data);
    switch (profileData.data.type) {
      case Roles.CUSTOMER:
        return await navigate('/customer/orders');
      case Roles.DELIVERY:
        return await navigate('/organizer/orders');
      case Roles.OPERATOR:
        return await navigate('/operator/organizations');
      case Roles.ADMIN:
        return await navigate('/admin/users');
    }
  }
};
