import { API } from '../../../../Services/axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { OrderCustomerTableProps } from '../../../../Models/Order';
import { Tabs } from 'antd';
import { getOrdersTabsItems } from './Component/tabs-items';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';
import Toolbar from './Toolbar';

interface CustomerOrdersProps extends OrderCustomerTableProps {
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

const Orders = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<OrderCustomerTableProps>({
    all: 0,
    on_signing: 0,
    without_offers: 0,
    with_offers: 0,
    with_draft_agreements: 0,
    signed: 0,
    drafts: 0,
    archive: 0,
    orders: [],
  });
  const [columnsSettings, setColumnsSettings] = useState<unknown>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('tab')! !== '0' ? searchParams.get('tab')! : 'ALL');
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';

  const restartTable = () => {
    if (tab !== null) {
      setLoading(true);
      API.get(`/api/v1/order/order-list?filter=${tab}&page=${page}&size=${size}&sort=${sort}&sortField=${sortField}`)
        .then((response) => setData(response.data))
        .catch((error) => showErrorModal({ error }))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    restartTable();
    API.get(`/api/v1/table-settings/order`)
      .then((response) => setColumnsSettings(response.data))
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && tab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('tab', tab);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
    restartTable();
  }, [tab, page, size, sort, sortField]);

  const ordersProps: CustomerOrdersProps = {
    orders: data.orders,
    all: data.all,
    on_signing: data.on_signing,
    without_offers: data.without_offers,
    with_offers: data.with_offers,
    with_draft_agreements: data.with_draft_agreements,
    signed: data.signed,
    drafts: data.drafts,
    archive: data.archive,
    loading: loading,
    columnsSettings: columnsSettings,
    setTab: setTab,
  };

  const tabsItems = getOrdersTabsItems(ordersProps);

  return (
    <div>
      <Toolbar columnsSettings={columnsSettings} setColumnsSettings={setColumnsSettings} />
      <div className="orders__tabs-table">
        <Tabs
          activeKey={tab}
          onTabClick={(key) => {
            setTab(key);
            setSearchParams((params) => {
              params.set('page', '1');
              return searchParams;
            });
            window.history.replaceState(null, '', `/customer/orders?tab=${key}&page=${page}&size=${size}`);
          }}
          items={tabsItems}
          className="tabs"
        />
      </div>
    </div>
  );
};

export default Orders;
