import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider, Layout } from 'antd';
import CreateOrder from '../Modules/Customer/Order/Create/CreateOrder';

import '../Assets/Styles/index.scss';

import { default as AdminUserProfile } from '../Modules/Admin/Users/Detail/UserProfile';
import { default as AdminUsers } from '../Modules/Admin/Users/List/Users';
import { default as CustomerContractPage } from '../Modules/Customer/Contract/Detail/Contract';
import { default as CustomerContracts } from '../Modules/Customer/Contract/List/Contracts';
import { default as CustomerOrder } from '../Modules/Customer/Order/Detail/Order';
import { default as CustomerOrders } from '../Modules/Customer/Order/List/Orders';
import { HelmetProvider } from 'react-helmet-async';
import { default as OrganizerContractPage } from '../Modules/Organizer/Contract/Detail/Contract';
import { default as OrganizerContracts } from '../Modules/Organizer/Contract/List/Contracts';
import { default as OrganizerOrder } from '../Modules/Organizer/Order/Detail/Order';
import { default as OrganizerOrders } from '../Modules/Organizer/Order/List/All/Orders';
import { default as OrganizerOrdersInProgress } from '../Modules/Organizer/Order/List/InProgress/Orders';
import { Roles } from '../Constants/Profile';
import { customerContractsColsWidth, customerOrdersColsWidth } from '../Constants/CustomerColsWidth';
import { orgContractsColsWidth, orgOrdersColsWidth } from '../Constants/OrganizerColsWidth';
import { saveInitialColsWidth } from '../Utils/saveInitialColsWidth';
import { useAppSelector } from '../Store/hooks';
import { useEffect, useState } from 'react';
import AnalyticalReport from '../Modules/Admin/AnalyticalReport/AnalyticalReport';
import AppFooter from '../Components/Footer/AppFooter';
import AppHeader from '../Components/Header/AppHeader';
import Bill from '../Modules/Organizer/Profile/Bill';
import Claims from '../Components/Claims/List/Claims';
import ContractsTable from '../Modules/Operator/Contracts/List/ContractsTable';
import MainPage from '../Components/MainPage/MainPage';
import OperatorClaims from '../Modules/Operator/Claims/List/Claims';
import OperatorContractDetails from '../Modules/Operator/Contracts/Detail/Contract';
import OperatorOrder from '../Modules/Operator/Orders/Detail/Order';
import OperatorProfile from '../Modules/Admin/Operators/Detail/OperatorProfile';
import OperatorsTable from '../Modules/Admin/Operators/List/OperatorsTable';
import OrdersTable from '../Modules/Operator/Orders/List/OrdersTable';
import OrganizationProfile from '../Modules/Operator/Organizations/Detail/OrganizationProfile';
import Organizations from '../Modules/Operator/Organizations/List/Organizations';
import PageNotFound from '../Components/ResultPage/PageNotFound';
import ProfileWrapper from '../Components/Profile/ProfileWrapper';
import ProtectedRoute from '../Components/ProtectedRoute/ProtectedRoute';
import RegistrationPage from '../Components/Registration/RegistrationPage';
import SetPasswordPage from '../Modules/Operator/Profile/SetPasswordPage';
import SystemSettings from '../Modules/Admin/SystemSettings/SystemSettings';
import UnsubscribePage from '../Components/ResultPage/UnsubscribePage';
import UserProfile from '../Modules/Operator/Users/Detail/UserProfile';
import Users from '../Modules/Operator/Users/List/Users';
import ruRu from 'antd/es/locale/ru_RU';

const { Content } = Layout;

function App() {
  const role = useAppSelector((state) => state.user.role);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (role === Roles.DELIVERY) {
      saveInitialColsWidth('orgOrders', orgOrdersColsWidth);
      saveInitialColsWidth('orgContracts', orgContractsColsWidth);
    }
    if (role === Roles.CUSTOMER) {
      saveInitialColsWidth('customerOrders', customerOrdersColsWidth);
      saveInitialColsWidth('customerContracts', customerContractsColsWidth);
    }
  }, []);

  const roleMainPageLink = () => {
    if (role) {
      switch (role) {
        case Roles.CUSTOMER:
          return '/customer/orders';
        case Roles.DELIVERY:
          return '/organizer/orders';
        case Roles.OPERATOR:
          return '/operator/organizations';
      }
    }
    return '/registration';
  };

  return (
    <HelmetProvider>
      <ConfigProvider locale={ruRu}>
        <div className="backdrop"></div>
        <div className="app">
          <Layout>
            <BrowserRouter>
              <AppHeader setLoading={setLoading} />
              <Content className="app__content">
                <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route
                    path="/registration"
                    element={role ? <Navigate to={roleMainPageLink()} replace={true} /> : <RegistrationPage />}
                  />
                  <Route
                    path="/registration/:type"
                    element={role ? <Navigate to={roleMainPageLink()} replace={true} /> : <RegistrationPage />}
                  />
                  <Route path="/unsubscribe/:token" element={<UnsubscribePage />} />
                  <Route path="/profile" element={<ProfileWrapper />} />
                  <Route
                    path="/profile/bill"
                    element={
                      <ProtectedRoute routeRole={Roles.DELIVERY} loading={loading}>
                        <Bill />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/customer/orders"
                    element={
                      <ProtectedRoute routeRole={Roles.CUSTOMER} loading={loading}>
                        <CustomerOrders />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/customer/orders/create"
                    element={
                      <ProtectedRoute routeRole={Roles.CUSTOMER} loading={loading}>
                        <CreateOrder />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/customer/dublicate/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.CUSTOMER} loading={loading}>
                        <CreateOrder />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/customer/orders/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.CUSTOMER} loading={loading}>
                        <CustomerOrder />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/customer/draft/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.CUSTOMER} loading={loading}>
                        <CreateOrder />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/customer/contracts"
                    element={
                      <ProtectedRoute routeRole={Roles.CUSTOMER} loading={loading}>
                        <CustomerContracts />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/customer/contracts/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.CUSTOMER} loading={loading}>
                        <CustomerContractPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/customer/support"
                    element={
                      <ProtectedRoute routeRole={Roles.CUSTOMER} loading={loading}>
                        <Claims />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/organizer/orders"
                    element={
                      <ProtectedRoute routeRole={Roles.DELIVERY} loading={loading}>
                        <OrganizerOrders />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/organizer/orders/in-progress"
                    element={
                      <ProtectedRoute routeRole={Roles.DELIVERY} loading={loading}>
                        <OrganizerOrdersInProgress />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/organizer/orders/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.DELIVERY} loading={loading}>
                        <OrganizerOrder />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/organizer/contracts"
                    element={
                      <ProtectedRoute routeRole={Roles.DELIVERY} loading={loading}>
                        <OrganizerContracts />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/organizer/contracts/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.DELIVERY} loading={loading}>
                        <OrganizerContractPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/organizer/support"
                    element={
                      <ProtectedRoute routeRole={Roles.DELIVERY} loading={loading}>
                        <Claims />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/operator/organizations"
                    element={
                      <ProtectedRoute routeRole={Roles.OPERATOR} loading={loading}>
                        <Organizations />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/operator/organizations/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.OPERATOR} loading={loading}>
                        <OrganizationProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/operator/users"
                    element={
                      <ProtectedRoute routeRole={Roles.OPERATOR} loading={loading}>
                        <Users />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/operator/users/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.OPERATOR} loading={loading}>
                        <UserProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/operator/orders"
                    element={
                      <ProtectedRoute routeRole={Roles.OPERATOR} loading={loading}>
                        <OrdersTable />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/operator/orders/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.OPERATOR} loading={loading}>
                        <OperatorOrder />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/operator/contracts"
                    element={
                      <ProtectedRoute routeRole={Roles.OPERATOR} loading={loading}>
                        <ContractsTable />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/operator/contracts/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.OPERATOR} loading={loading}>
                        <OperatorContractDetails />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/operator/claims"
                    element={
                      <ProtectedRoute routeRole={Roles.OPERATOR} loading={loading}>
                        <OperatorClaims />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/operator/set-password/:registrationLink"
                    element={role ? <Navigate to={roleMainPageLink()} replace={true} /> : <SetPasswordPage />}
                  />

                  <Route
                    path="/admin/users"
                    element={
                      <ProtectedRoute routeRole={Roles.ADMIN} loading={loading}>
                        <AdminUsers />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/users/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.ADMIN} loading={loading}>
                        <AdminUserProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/operators"
                    element={
                      <ProtectedRoute routeRole={Roles.ADMIN} loading={loading}>
                        <OperatorsTable />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/operators/:id"
                    element={
                      <ProtectedRoute routeRole={Roles.ADMIN} loading={loading}>
                        <OperatorProfile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/analitics"
                    element={
                      <ProtectedRoute routeRole={Roles.ADMIN} loading={loading}>
                        <AnalyticalReport />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/settings"
                    element={
                      <ProtectedRoute routeRole={Roles.ADMIN} loading={loading}>
                        <SystemSettings />
                      </ProtectedRoute>
                    }
                  />

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </Content>
              <AppFooter />
            </BrowserRouter>
          </Layout>
          <div id="errorModal" />
        </div>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default App;
