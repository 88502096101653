/* eslint-disable no-undef */
import axios from 'axios';

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

API.interceptors.request.use(
  function (config) {
    return {
      ...config,
    };
  },
  function (error) {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  function (response) {
    let respUrl = response.request.responseURL;
    if (respUrl.includes(process.env.REACT_APP_KEYCLOAK_URL) && respUrl.includes('realms')) {
      localStorage.removeItem('userInfo');
      window.location.href = respUrl;
    }
    if (response.status === 304) {
      window.location.href = respUrl;
    }
    if (response.status === 400) throw new Error(response.details);
    return response;
  },
  function (error) {
    throw error.response.data.details;
  },
);
